import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { TreatmentProductAutoTreatFormValues, TreatmentProductAutoTreat } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface TreatmentProductsAutoTreatContextInterface {
  fetchCachedTreatmentProductsOptionsAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchTreatmentProductsOptionsAutoTreat?: (machineSerial: string) => Promise<void>;

  fetchCachedTreatmentProductsAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchTreatmentProductsAutoTreat?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  treatmentProductsRefreshedAt?: string;
  treatmentProducts?: any[];
  treatmentProductsLoading?: boolean;

  createTreatmentProductAutoTreat?: (
    machineSerial: string,
    formValues: TreatmentProductAutoTreatFormValues,
  ) => Promise<void>;

  updateTreatmentProductAutoTreat?: (
    machineSerial: string,
    formValues: TreatmentProductAutoTreatFormValues,
  ) => Promise<void>;
  treatmentProductSubmitting?: boolean;

  deleteTreatmentProductAutoTreat?: (treatment_product_id: string) => Promise<void>;
  treatmentProductDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveTreatmentProductAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeTreatmentProductAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;

  options?: Record<string, any[]>;
  manufacturerOptions?: Record<string, any[]>;
}

const TreatmentProductsAutoTreatContext = React.createContext<TreatmentProductsAutoTreatContextInterface>({});

const TreatmentProductsAutoTreatContextConsumer = TreatmentProductsAutoTreatContext.Consumer;
const TreatmentProductsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [treatmentProducts, setTreatmentProductsAutoTreat] = React.useState<TreatmentProductAutoTreat[]>([]);
  const [treatmentProductsLoading, setTreatmentProductsAutoTreatLoading] = React.useState(true);
  const [treatmentProductsRefreshedAt, setTreatmentProductsAutoTreatRefreshedAt] = React.useState<string>();
  const [treatmentProduct, setTreatmentProductAutoTreat] = React.useState({});
  const [activeTreatmentProductAutoTreat, setActiveTreatmentProductAutoTreat] = React.useState({});

  const [treatmentProductSubmitting, setTreatmentProductAutoTreatSubmitting] = React.useState(false);
  const [treatmentProductDeleting, setTreatmentProductAutoTreatDeleting] = React.useState(false);

  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('Active');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  const [options, setOptions] = React.useState({});
  const [manufacturerOptions, setManufacturerOptions] = React.useState({});

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setTreatmentProductsAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedTreatmentProductsOptionsAutoTreat = async (machineSerial: string) => {
    axios
      .get<string, any>(`api/autotreat/onprem/treatment_product_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setOptions({
          ...options,
          product_type_options: response.data.results
            .filter((choice) => choice.table === 'LiquidType')
            .map((choice) => ({ label: choice.name, value: choice.id })),
        });
      })
      .catch((error) => {
        setOptions([]);
      });
  };

  const fetchTreatmentProductsOptionsAutoTreat = async (machineSerial: string) => {
    let retryCount = 0;
    axios
      .get<string, any>(`api/autotreat/onprem/treatment_product_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })

      .then((res) => {
        const fetchTimer = setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_product_options/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setOptions({
                ...options,
                product_type_options: response.data.results
                  .filter((choice) => choice.table === 'LiquidType')
                  .map((choice) => ({ label: choice.name, value: choice.id })),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const fetchCachedTreatmentProductsAutoTreat = async (machineSerial: string) => {
    setTreatmentProductsAutoTreatLoading(true);
    setTreatmentProductsAutoTreatRefreshedAt(null);

    axios
      .get<string, any>(`api/autotreat/onprem/treatment_products/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTreatmentProductsAutoTreatLoading(false);
        setTreatmentProductsAutoTreat(response.data.results);
        setTreatmentProductsAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setTreatmentProductsAutoTreatLoading(false);
        setTreatmentProductsAutoTreat([]);
        setTreatmentProductsAutoTreatRefreshedAt(null);
      });
  };

  const fetchTreatmentProductsAutoTreat = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setTreatmentProductsAutoTreatLoading(true);
    setTreatmentProductsAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/treatment_products/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_products/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setTreatmentProductsAutoTreatLoading(false);
              setTreatmentProductsAutoTreat(response.data.results);
              setTreatmentProductsAutoTreatRefreshedAt(response.data.created_at);
              onSuccess();
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductsAutoTreat([]);
                setTreatmentProductsAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateTreatmentProductAutoTreat = async (
    machineSerial: string,
    formValues: TreatmentProductAutoTreatFormValues,
  ) => {
    setDetailPage(true);
    setTreatmentProductAutoTreatSubmitting(true);
    setTreatmentProductsAutoTreatLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/treatment_products/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setTreatmentProductsAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchTreatmentProductsOptionsAutoTreat(machineSerial);
              fetchTreatmentProductsAutoTreat(activeMachine?.value, '', '', '', () => {
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductAutoTreatSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductAutoTreatSubmitting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createTreatmentProductAutoTreat = async (
    machineSerial: string,
    formValues: TreatmentProductAutoTreatFormValues,
  ) => {
    setTreatmentProductAutoTreatSubmitting(true);
    setCreatingPage(true);
    setTreatmentProductsAutoTreatLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/treatment_products/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setTreatmentProductsAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchTreatmentProductsOptionsAutoTreat(machineSerial);
              fetchTreatmentProductsAutoTreat(activeMachine?.value, '', '', '', () => {
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductAutoTreatSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductAutoTreatSubmitting(false);
                setTreatmentProductAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteTreatmentProductAutoTreat = async (treatment_product_id: string) => {
    setTreatmentProductAutoTreatDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    console.log('');
    console.log(`sending delete statement with treatment_product_id: ${treatment_product_id}`);
    console.log('');

    axios
      .delete<any, any>(
        `api/autotreat/onprem/treatment_products/${treatment_product_id}/?serial-number=${activeMachine?.value}`,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/treatment_products/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setTreatmentProductsAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false)
              }
              setTreatmentProductAutoTreatDeleting(false);
              fetchTreatmentProductsAutoTreat(activeMachine?.value, '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setTreatmentProductsAutoTreatLoading(false);
                setTreatmentProductAutoTreatDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <TreatmentProductsAutoTreatContext.Provider
      value={{
        fetchCachedTreatmentProductsOptionsAutoTreat,
        fetchTreatmentProductsOptionsAutoTreat,
        fetchCachedTreatmentProductsAutoTreat,
        fetchTreatmentProductsAutoTreat,
        treatmentProducts,
        treatmentProductsRefreshedAt,
        treatmentProductsLoading,

        createTreatmentProductAutoTreat,
        updateTreatmentProductAutoTreat,
        treatmentProductSubmitting,

        deleteTreatmentProductAutoTreat,
        treatmentProductDeleting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveTreatmentProductAutoTreat,
        activeTreatmentProductAutoTreat,

        setActiveMachine,
        activeMachine,

        options,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </TreatmentProductsAutoTreatContext.Provider>
  );
};

export {
  TreatmentProductsAutoTreatContextProvider,
  TreatmentProductsAutoTreatContextConsumer,
  TreatmentProductsAutoTreatContext,
};

/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Transaction ID',
    selector: 'pickup_transaction_id',
    sortable: true,
    grow: 0.5,
  },
  {
    name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'pu_date_and_time',
    sortable: true,
    grow: 0.7,
    cell: (row: any) => (
      <p>
        {row.order_complete_date_and_time_utc
          ? format(new Date(`${row?.order_complete_date_and_time_utc} UTC`), 'MM/dd/yyyy hh:mm aaa')
          : ''}
      </p>
    )
  },
  {
    name: 'Order Name',
    selector: 'pickup_order_name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Variety',
    selector: 'variety',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Recipe',
    selector: 'liquid_recipe_name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Api Partner',
    selector: 'api_partner',
    sortable: true,
    grow: 0.3,
  },
  {
    name: 'Partner ID 1',
    selector: 'order_partner_record_id_1',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Partner ID 2',
    selector: 'order_partner_record_id_2',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Response',
    selector: 'response',
    sortable: true,
    cell: (row) => row.response.split('addLineBreak').map((line, index) => <div key={index}>{line}</div>),
    grow: 2.0,
  },
];

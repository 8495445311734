/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoBulkTransactionFilterBar from '../../../modules/shared/form/AutoBulkTransactionFilterBar';
import filters from '../constants/sourceFilters';
import columns from '../constants/sourceColumns';
import { AutoBulkFiltersContext } from '../contexts/AutoBulkFiltersContext';
import { SourcesContext } from '../contexts/SourcesContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

const SourceSummary: React.FunctionComponent = () => {
  const { filterOptions, fetchFilterOptions, errorMessage, convertFilterValuesToUrlParams } =
    React.useContext(AutoBulkFiltersContext);
  const { sources, fetchSources, sourceMetrics, sourcesLoading, exportTransactions } = React.useContext(SourcesContext);
  const { fetchAutoBulkMachines, autoBulkMachines } = React.useContext(MachinesContext);

  const [activePage, setActivePage] = React.useState(1);
  const [displayUOM, setDisplayUOM] = React.useState('lbs');
  const [filterType] = React.useState('AutoBulk Source Summary');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [perPage, setPerPage] = React.useState<number>(10);

  React.useEffect(() => {
    fetchFilterOptions();
    fetchSources();
    fetchAutoBulkMachines();
  }, []);

  const handleFilter = (filterParams: string) => {
    fetchSources(filterParams);
    fetchFilterOptions(filterParams);
  };

  const handlePageChange = (page: number) => {
    const machines = JSON.parse(localStorage.getItem('autoBulkMachines'));
    const startDate = localStorage.getItem('startDate');
    const endDate = localStorage.getItem('endDate');

    const filterHeaders = convertFilterValuesToUrlParams(
      machines,
      filterValues as Record<string, any[]>,
    );

    fetchSources(`${filterHeaders}&page=${page}`);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Source Summary
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoBulkTransactionFilterBar
            applyFilter={handleFilter}
            displayUOM={displayUOM}
            setDisplayUOM={setDisplayUOM}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            localStorageMachineKey="autoBulkMachines"
            exportTransactions={exportTransactions}
            includeProducts={null}
            setIncludeProducts={null}
            transactionMetrics={null}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <Card style={{ padding: 30 }}>
        <DataTable
          noHeader
          columns={columns(displayUOM)}
          data={sources}
          progressPending={sourcesLoading}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationTotalRows={sourceMetrics?.total_records}
          paginationRowsPerPageOptions={[10, 25, 50]}
        />
      </Card>
      <br />
    </>
  );
};

export default SourceSummary;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { MenuItem, Grid, styled } from '@mui/material';
import { format } from 'date-fns';

const StyledDatePicker = styled(DesktopDatePicker)(() => ({
  '& .MuiPickersDay-root': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#ccc',
    boxSizing: 'border-box',
  },
}));

const DateSelectFilter: React.FC = () => {
  const [timePeriod, setTimePeriod] = React.useState<string>(localStorage.getItem('timePeriod') || '');
  const [startDate, setStartDate] = React.useState<string>(localStorage.getItem('startDate') || '');
  const [endDate, setEndDate] = React.useState<string>(localStorage.getItem('endDate') || '');

  const handleTimePeriodChange = (event: any) => {
    const newTimePeriod = event.target.value;
    localStorage.setItem('timePeriod', newTimePeriod);
    localStorage.setItem('startDate', '');
    localStorage.setItem('endDate', '');
    setTimePeriod(newTimePeriod);
    setStartDate('');
    setEndDate('');
  };

  const handleStartDateChange = (newValue: any) => {
    try {
      setStartDate(format(newValue, 'MM/dd/yyyy'));
      localStorage.setItem('startDate', format(newValue, 'MM/dd/yyyy'));
    } catch {
      setStartDate('');
      localStorage.setItem('startDate', '');
    }
  };

  const handleEndDateChange = (newValue: any) => {
    try {
      setEndDate(format(newValue, 'MM/dd/yyyy'));
      localStorage.setItem('endDate', format(newValue, 'MM/dd/yyyy'));
    } catch {
      setEndDate('');
      localStorage.setItem('endDate', '');
    }
  };

  return (
    <FormControl sx={{ minWidth: 120, marginTop: '7px' }}>
        <>
          <div className="Polaris-Labelled__LabelWrapper" style={{ margin: '-7px 0 9px' }}>
            <div className="Polaris-Label">
              <span className="Polaris-Label__Text">When</span>
            </div>
          </div>
          <Select
            variant="outlined"
            placeholder="When"
            style={{ marginTop: '-4px', height: '32px', fontSize: '12px' }}
            value={timePeriod}
            onChange={handleTimePeriodChange}
            >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="yesterday">Yesterday</MenuItem>
            <MenuItem value="last 7 days">Last 7 Days</MenuItem>
            <MenuItem value="last 30 days">Last 30 Days</MenuItem>
            <MenuItem value="last 6 months">Last 6 Months</MenuItem>
            <MenuItem value="last 12 months">Last 12 Months</MenuItem>
            <MenuItem value="ytd">Year To Date</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
            <MenuItem value="preset" style={{ display: 'none' }}>{`${startDate} to ${endDate}`}</MenuItem>
          </Select>
          {timePeriod === 'Custom' && (
            <>
              <div className="Polaris-Labelled__LabelWrapper" style={{ margin: '-7px 0 24px' }} />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container>
                  <Grid item>
                    <StyledDatePicker
                      label="Start Date"
                      inputFormat="MM/dd/yyyy"
                      value={startDate}
                      onChange={(newValue: any) => handleStartDateChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          InputProps={{ readOnly: true }}
                          sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                          {...params}
                          onChange={() => null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <StyledDatePicker
                      label="End Date"
                      inputFormat="MM/dd/yyyy"
                      value={endDate}
                      onChange={(newValue: any) => handleEndDateChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          InputProps={{ readOnly: true }}
                          sx={{ '& .MuiInputBase-input': { padding: '13.5px !important' } }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </>
          )}
        </>
    </FormControl>
  );
};

export default DateSelectFilter;

import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import { CustomerAutoTreatFormValues, CustomerAutoTreat } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface CustomersAutoTreatContextInterface {
  fetchCachedCustomersAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchCustomersAutoTreat?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;
  customersRefreshedAt?: string;
  customers?: any[];
  customersLoading?: boolean;

  createCustomerAutoTreat?: (formValues: CustomerAutoTreatFormValues, onSuccess: () => void) => Promise<void>;

  updateCustomerAutoTreat?: (userId: string, formValues: CustomerAutoTreatFormValues) => Promise<void>;
  customerSubmitting?: boolean;

  deleteCustomerAutoTreat?: (customerId: string) => Promise<void>;
  customerDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveCustomerAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeCustomerAutoTreat?: CustomerAutoTreat;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;
}
const CustomersAutoTreatContext = React.createContext<CustomersAutoTreatContextInterface>({});

const CustomersAutoTreatContextConsumer = CustomersAutoTreatContext.Consumer;
const CustomersAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [customers, setCustomersAutoTreat] = React.useState([]);
  const [customersLoading, setCustomersAutoTreatLoading] = React.useState(true);
  const [customersRefreshedAt, setCustomersAutoTreatRefreshedAt] = React.useState<string>();
  const [customer, setCustomerAutoTreat] = React.useState({});
  const [activeCustomerAutoTreat, setActiveCustomerAutoTreat] = React.useState({});
  const [customerLoading, setCustomerAutoTreatLoading] = React.useState(true);
  const [customerSubmitting, setCustomerAutoTreatSubmitting] = React.useState(false);
  const [customerDeleting, setCustomerAutoTreatDeleting] = React.useState(false);
  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
  });
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('All');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setCustomersAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchCachedCustomersAutoTreat = async (machineSerial: string) => {
    setCustomersAutoTreatLoading(true);
    setCustomersAutoTreatRefreshedAt(null);

    axios
      .get<string, any>(`api/autotreat/onprem/customers/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setCustomersAutoTreatLoading(false);
        setCustomersAutoTreat(response.data.results);
        setCustomersAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setCustomersAutoTreatLoading(false);
        setCustomersAutoTreat([]);
        setCustomersAutoTreatRefreshedAt(null);
      });
  };

  const fetchCustomersAutoTreat = async (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setCustomersAutoTreatLoading(true);
    setCustomersAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/customers/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${statusFilter}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customers/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setCustomersAutoTreatLoading(false);
              setCustomersAutoTreat(response.data.results);
              setCustomersAutoTreatRefreshedAt(response.data.created_at);
              onSuccess();
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load customers');
                setCustomersAutoTreatLoading(false);
                setCustomersAutoTreat([]);
                setCustomersAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateCustomerAutoTreat = async (customerId: string, formValues: CustomerAutoTreatFormValues) => {
    setDetailPage(true);
    setCustomerAutoTreatSubmitting(true);
    setCustomersAutoTreatLoading(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/customers/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setCustomerAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchCustomersAutoTreat(activeMachine?.value, '', '', '', () => {
                setCustomerAutoTreatLoading(false);
                setCustomerAutoTreatSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setCustomerAutoTreatLoading(false);
                setCustomerAutoTreatSubmitting(false);

                setCustomerAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createCustomerAutoTreat = async (formValues: CustomerAutoTreatFormValues, onSuccess?: () => void) => {
    setCustomerAutoTreatSubmitting(true);
    setCreatingPage(true);
    setCustomersAutoTreatLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/customers/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setCustomerAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchCustomersAutoTreat(activeMachine?.value, '', '', '', () => {
                setCustomerAutoTreatLoading(false);
                setCustomerAutoTreatSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setCustomerAutoTreatLoading(false);
                setCustomerAutoTreatSubmitting(false);
                setCustomerAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const deleteCustomerAutoTreat = async (customerId: string) => {
    setCustomerAutoTreatDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(
        `api/autotreat/onprem/customers/${customerId}/?serial-number=${activeMachine?.value}`,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/customers/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setCustomerAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false)
              }
              setCustomerAutoTreatDeleting(false);
              fetchCustomersAutoTreat(activeMachine?.value, '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setCustomerAutoTreatLoading(false);
                setCustomerAutoTreatDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <CustomersAutoTreatContext.Provider
      value={{
        fetchCachedCustomersAutoTreat,
        fetchCustomersAutoTreat,
        customers,
        customersRefreshedAt,
        customersLoading,

        createCustomerAutoTreat,
        updateCustomerAutoTreat,
        deleteCustomerAutoTreat,
        customerDeleting,
        customerSubmitting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveCustomerAutoTreat,
        activeCustomerAutoTreat,

        setActiveMachine,
        activeMachine,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </CustomersAutoTreatContext.Provider>
  );
};

export { CustomersAutoTreatContextProvider, CustomersAutoTreatContextConsumer, CustomersAutoTreatContext };

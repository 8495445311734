import React from 'react';
import { Card, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import AutoBulkTransactionFilterBar from '../../../modules/shared/form/AutoBulkTransactionFilterBar';
import filters from '../constants/productFilters';
import columns from '../constants/productDetailColumns';
import { AutoBulkFiltersContext } from '../contexts/AutoBulkFiltersContext';
import { ProductsContext } from '../contexts/ProductsContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';

const ProductDetailPage: React.FunctionComponent = () => {
  const { filterOptions, fetchFilterOptions, errorMessage, convertFilterValuesToUrlParams } =
    React.useContext(AutoBulkFiltersContext);
  const { products, fetchProductDetails, productMetrics, productsLoading, exportTransactions } =
    React.useContext(ProductsContext);
  const { fetchAutoBulkMachines, autoBulkMachines } = React.useContext(MachinesContext);

  const [activePage, setActivePage] = React.useState(1);
  const [filterType] = React.useState('AutoBulk Product Detail');
  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({});
  const [initialDataLoaded, setDataLoaded] = React.useState(false);
  const [perPage, setPerPage] = React.useState<number>(10);

  React.useEffect(() => {
    fetchAutoBulkMachines();
    fetchProductDetails();
    fetchFilterOptions();
  }, []);

  const handleFilter = (filterParams: string) => {
    fetchProductDetails(filterParams);
    fetchFilterOptions(filterParams);
  };

  const handlePageChange = (page: number) => {
    const machines = JSON.parse(localStorage.getItem('autoBulkMachines'));
    const startDate = localStorage.getItem('startDate');
    const endDate = localStorage.getItem('endDate');

    const filterHeaders = convertFilterValuesToUrlParams(
      machines,
      filterValues as Record<string, any[]>,
    );
    fetchProductDetails(`${filterHeaders}&page=${page}`);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Product Details
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <AutoBulkTransactionFilterBar
            applyFilter={handleFilter}
            filterChoices={filterOptions}
            filters={filters as any}
            filterType={filterType}
            filterValues={filterValues}
            localStorageMachineKey="autoBulkMachines"
            setFilterValues={setFilterValues}
            showCombineCheckbox={false}
            exportTransactions={exportTransactions}
            includeProducts={null}
            setIncludeProducts={null}
            transactionMetrics={null}
            treatmentProductMetrics={null}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />
      <Card style={{ padding: 30 }}>
        <DataTable
          noHeader
          columns={columns()}
          data={products}
          progressPending={productsLoading}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationTotalRows={productMetrics?.total_records}
          paginationRowsPerPageOptions={[10, 25, 50]}
          tableSums={null}
        />
      </Card>
      <br />
    </>
  );
};

export default ProductDetailPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import MachineRequestFilterBar from '../components/MachineRequestFilterBar';
import machineRequestColumns from '../constants/machineRequestColumns';
import { MachineRequestsContext } from '../contexts/MachineRequestsContext';
import { UserMachinesContext } from '../../machinesAccountManagement/contexts/UserMachinesContext';
import { MachineRequestFiltersContext } from '../contexts/MachineRequestFiltersContext';

const MachineRequestsPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineRequests, fetchMachineRequests, machineRequestsLoading, totalMachineRequestsCount, errorMessage } =
    React.useContext(MachineRequestsContext);
  const { machineRequestFilterOptions } =
    React.useContext(MachineRequestFiltersContext);

  const { fetchUserAndCompanyMachines, accountMachines, machinesLoading } = React.useContext(UserMachinesContext);

  const [filterValues, setFilterValues] = React.useState<Record<string, string | string[]>>({
    requestType: 'All',
    requestCategory: 'All',
    status: 'All',
  });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState<number>(10);
  const [filterParamsString, setFilterParamsString] = React.useState<string>('');

  React.useEffect(() => {
    fetchUserAndCompanyMachines();
  }, []);

  const handleFilter = (filterParams: string) => {
    setPage(1)
    setPerPage(perPage)
    fetchMachineRequests(filterParams, page.toString(), perPage.toString());
    setFilterParamsString(filterParams)
  };

  const handlePageChange = async (page: number) => {
    setPage(page)
    fetchMachineRequests(filterParamsString, page.toString(), perPage.toString());
  };

  const handlePerPageChange = async (perPage: number) => {
    setPerPage(perPage)
    setPage(1)
    fetchMachineRequests(filterParamsString, '1', perPage.toString());
  };

  const handleViewMachineRequestDetails = (machine_request_id) => {
    history.push(`/machine_requests/${machine_request_id}/machine_request_records`);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Machine Requests
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <MachineRequestFilterBar
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            filterOptions={machineRequestFilterOptions}
            machineChoices={accountMachines}
            machineChoicesLoading={machinesLoading}
            applyFilter={handleFilter}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={machineRequestColumns}
            dense
            data={machineRequests}
            defaultSortField="machine_request_id"
            defaultSortAsc={false}
            progressPending={machineRequestsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            pagination
            paginationServer
            paginationTotalRows={totalMachineRequestsCount}
            paginationDefaultPage={page}
            onChangePage={(page) => handlePageChange(page)}
            onChangeRowsPerPage={(per_page) => handlePerPageChange(per_page)}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 5000]}
            onRowClicked={(row) => {
              handleViewMachineRequestDetails(row.machine_request_id);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default MachineRequestsPage;

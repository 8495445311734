import React, { Dispatch, SetStateAction } from 'react';
import axios from '../../../modules/utils/axios.utils';
import seedSelectOptions from '../constants/seedSelectOptions';
import { SeedAutoTreatFormValues } from '../model';
import { MACHINE_MANAGEMENT_RETRY_INTERVAL, MACHINE_MANAGEMENT_TOTAL_RETRIES } from '../../../modules/utils/env';
import { SnackBarContext } from '../../../modules/snackBar/contexts/SnackBarContext';

interface SeedsAutoTreatContextInterface {
  fetchCachedSeedsAutoTreat?: (machineSerial: string) => Promise<void>;
  fetchCachedSeedOptions?: (machineSerial: string) => Promise<void>;
  fetchSeedOptions?: (machineSerial: string) => Promise<void>;

  fetchSeedsAutoTreat?: (
    machineSerial: string,
    genericFilter: string,
    statusFilter: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => Promise<void>;

  seedsRefreshedAt?: string;
  seeds?: any[];
  seedsLoading?: boolean;

  createSeedAutoTreat?: (
    formValues: SeedAutoTreatFormValues,
    machineSerial: string,
    onSuccess: () => void,
  ) => Promise<void>;

  updateSeedAutoTreat?: (userId: string, formValues: SeedAutoTreatFormValues, machineSerial: string) => Promise<void>;
  seedSubmitting?: boolean;
  setSeedSubmitting?: Dispatch<SetStateAction<boolean>>;

  deleteSeedAutoTreat?: (seedVarietyId: string) => Promise<void>;
  seedDeleting?: boolean;

  creatingPage?: boolean;
  setCreatingPage?: Dispatch<SetStateAction<boolean>>;
  detailPage?: boolean;
  setDetailPage?: Dispatch<SetStateAction<boolean>>;

  setActiveMachine?: Dispatch<SetStateAction<Record<string, string>>>;
  activeMachine?: Record<string, string>;

  setActiveSeedAutoTreat?: Dispatch<SetStateAction<Record<string, string>>>;
  activeSeedAutoTreat?: Record<string, string>;

  handleGenericFilterChange?: (genericFilter: string) => Promise<void>;
  genericFilter?: string;

  handleStatusFilterChange?: (statusFilter: string) => Promise<void>;
  statusFilter?: string;

  handleApiLinkedFilterChange?: (apiLinkedFilter: string) => Promise<void>;
  apiLinkedFilter?: string;

  fetchOptions?: () => Promise<void>;
  options?: any;
}

const SeedsAutoTreatContext = React.createContext<SeedsAutoTreatContextInterface>({});

const SeedsAutoTreatContextConsumer = SeedsAutoTreatContext.Consumer;
const SeedsAutoTreatContextProvider: React.FC = ({ children }) => {
  const { showErrorSnackBar, showSuccessSnackBar, showMachineManagementSnackBar } = React.useContext(SnackBarContext);

  const [seeds, setSeedsAutoTreat] = React.useState([]);
  const [seedsLoading, setSeedsAutoTreatLoading] = React.useState(true);
  const [seedsRefreshedAt, setSeedsAutoTreatRefreshedAt] = React.useState<string>();
  const [seed, setSeedAutoTreat] = React.useState({});
  const [activeSeedAutoTreat, setActiveSeedAutoTreat] = React.useState({});

  const [seedSubmitting, setSeedSubmitting] = React.useState(false);
  const [seedDeleting, setSeedAutoTreatDeleting] = React.useState(false);

  const [creatingPage, setCreatingPage] = React.useState<boolean>(false);
  const [detailPage, setDetailPage] = React.useState<boolean>(false);
  const [activeMachine, setActiveMachine] = React.useState<Record<string, string>>({
    label: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
    value: localStorage.getItem('machineManagementAutoTreatMachine') || 'Select A Machine',
  });
  const [options, setOptions] = React.useState({});
  const [genericFilter, setGenericFilter] = React.useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<string>('Active');
  const [apiLinkedFilter, setApiLinkedFilter] = React.useState<string>('All');

  React.useEffect(() => {
    if (activeMachine?.value === 'Select A Machine') setSeedsAutoTreatLoading(false);
  }, [activeMachine]);

  const fetchSeedOptions = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');
    let retryCount = 0;

    axios
      .get<string, any>(`api/autotreat/onprem/seed_options/?serial-number=${machineSerial}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seed_options/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setOptions({
                ...options,
                supplier_choices: response.data.results
                  .filter((choice) => choice.table === 'SeedSupplier')
                  .map((choice) => ({ label: choice.name, value: choice.id })),
                brand_choices: response.data.results
                  .filter((choice) => choice.table === 'SeedSupplierBrand')
                  .map((choice) => ({ label: choice.name, value: choice.id })),
                crop_choices: response.data.results
                  .filter((choice) => choice.table === 'Crop')
                  .map((choice) => ({ label: choice.name, value: choice.id })),
                amount_type_choices: response.data.results
                  .filter((choice) => choice.table === 'SeedAmountType')
                  .map((choice) => ({ label: choice.name, value: choice.id })),
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      })
      .catch((error) => {});
  };

  const fetchCachedSeedsAutoTreat = async (machineSerial: string) => {
    setSeedsAutoTreatLoading(true);
    setSeedsAutoTreatRefreshedAt(null);

    axios
      .get<string, any>(`api/autotreat/onprem/seeds/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setSeedsAutoTreatLoading(false);
        setSeedsAutoTreat(response.data.results);
        setSeedsAutoTreatRefreshedAt(response.data.created_at);
      })
      .catch((error) => {
        setSeedsAutoTreatLoading(false);
        setSeedsAutoTreat([]);
        setSeedsAutoTreatRefreshedAt(null);
      });
  };

  const fetchSeedsAutoTreat = async (
    machineSerial: string,
    genericFilter: string,
    recordStatus: string,
    apiLinkedFilter: string,
    onSuccess?: () => void,
  ) => {
    setSeedsAutoTreatLoading(true);
    setSeedsAutoTreatRefreshedAt(null);
    let retryCount = 0;

    axios
      .get<string, any>(
        `api/autotreat/onprem/seeds/?serial-number=${machineSerial}&generic-filter=${genericFilter}&status=${recordStatus}&api-linked=${apiLinkedFilter}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
        },
      )

      .then((res) => {
        const fetchTimer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds/${res.data.result}`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(fetchTimer);
              setSeedsAutoTreatLoading(false);
              setSeedsAutoTreat(response.data.results);
              setSeedsAutoTreatRefreshedAt(response.data.created_at);
              onSuccess();
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(fetchTimer);
                showErrorSnackBar('Unable to load seeds');
                setSeedsAutoTreatLoading(false);
                setSeedsAutoTreat([]);
                setSeedsAutoTreatRefreshedAt(null);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const updateSeedAutoTreat = async (seedId: string, formValues: SeedAutoTreatFormValues, machineSerial: string) => {
    setDetailPage(true);
    setSeedSubmitting(true);
    showSuccessSnackBar('Sending update to the machine.');
    setSeedsAutoTreatLoading(true);
    let retryCount = 0;

    axios
      .put<any, any>(
        `api/autotreat/onprem/seeds/0/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setSeedsAutoTreatLoading(false);
              setSeedSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchSeedOptions(machineSerial);
              fetchSeedsAutoTreat(activeMachine?.value, '', '', '', () => {
                setSeedsAutoTreatLoading(false);
                setSeedSubmitting(false);
              });
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setSeedsAutoTreatLoading(false);
                setSeedSubmitting(false);
                setSeedAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };

  const createSeedAutoTreat = async (
    formValues: SeedAutoTreatFormValues,
    machineSerial: string,
    onSuccess?: () => void,
  ) => {
    setSeedSubmitting(true);
    setCreatingPage(true);
    setSeedsAutoTreatLoading(true);
    showSuccessSnackBar('Sending record to the machine.');
    let retryCount = 0;

    axios
      .post<any, any>(
        `api/autotreat/onprem/seeds/?serial-number=${activeMachine?.value}`,
        { ...formValues },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setSeedsAutoTreatLoading(false);
              setSeedSubmitting(false);
              showMachineManagementSnackBar(response.data.results[0]);
              fetchSeedsAutoTreat(activeMachine?.value, '', '', '', () => {
                setSeedsAutoTreatLoading(false);
                setSeedSubmitting(false);
              });
              fetchSeedOptions(machineSerial);
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setSeedsAutoTreatLoading(false);
                setSeedSubmitting(false);
                setSeedAutoTreat([]);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };
  
  const deleteSeedAutoTreat = async (seedVarietyId: string) => {
    setSeedAutoTreatDeleting(true);
    showSuccessSnackBar('Sending update to the machine.');
    let retryCount = 0;

    axios
      .delete<any, any>(
        `api/autotreat/onprem/seeds/${seedVarietyId}/?serial-number=${activeMachine?.value}`,
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((res) => {
        const timer = window.setInterval(() => {
          axios
            .get<string, any>(`api/autotreat/onprem/seeds/${res.data.result}/`, {
              headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            })
            .then((response) => {
              window.clearInterval(timer);
              setSeedsAutoTreatLoading(false);
              showMachineManagementSnackBar(response.data.results[0]);
              if (response.data.results[0].status === 'Success') {
                setDetailPage(false)
              }
              setSeedAutoTreatDeleting(false);
              fetchSeedsAutoTreat(activeMachine?.value, '', '', '', () => {});
            })
            .catch((error) => {
              retryCount += 1;

              if (retryCount > MACHINE_MANAGEMENT_TOTAL_RETRIES) {
                window.clearInterval(timer);
                showErrorSnackBar('No response from machine');
                setSeedsAutoTreatLoading(false);
                setSeedAutoTreatDeleting(false);
              }
            });
        }, MACHINE_MANAGEMENT_RETRY_INTERVAL);
      });
  };


  const fetchCachedSeedOptions = async (machineSerial: string) => {
    const accessToken = sessionStorage.getItem('accessToken');

    axios
      .get<string, any>(`api/autotreat/onprem/seed_options/${machineSerial}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        setOptions({
          ...options,
          supplier_choices: response.data.results
            .filter((choice) => choice.table === 'SeedSupplier')
            .map((choice) => ({ label: choice.name, value: choice.id })),
          brand_choices: response.data.results
            .filter((choice) => choice.table === 'SeedSupplierBrand')
            .map((choice) => ({ label: choice.name, value: choice.id })),
          crop_choices: response.data.results
            .filter((choice) => choice.table === 'Crop')
            .map((choice) => ({ label: choice.name, value: choice.id })),
          amount_type_choices: response.data.results
            .filter((choice) => choice.table === 'SeedAmountType')
            .map((choice) => ({ label: choice.name, value: choice.id })),
        });
      })
      .catch((response) => {
        setOptions({
          ...options,
          supplier_choices: [],
          brand_choices: [],
          crop_choices: [],
        });
      });
  };

  const fetchOptions = async () => {
    setOptions({
      ...options,
      seedSelectOptions,
    });
  };

  const handleGenericFilterChange = async (genericFilter: string) => {
    setGenericFilter(genericFilter);
  };

  const handleStatusFilterChange = async (statusFilter: string) => {
    setStatusFilter(statusFilter);
  };

  const handleApiLinkedFilterChange = async (apiLinkedFilter: string) => {
    setApiLinkedFilter(apiLinkedFilter);
  };

  return (
    <SeedsAutoTreatContext.Provider
      value={{
        fetchCachedSeedsAutoTreat,
        fetchSeedsAutoTreat,
        seeds,
        seedsRefreshedAt,
        seedsLoading,
        fetchCachedSeedOptions,
        fetchSeedOptions,

        createSeedAutoTreat,
        updateSeedAutoTreat,
        seedSubmitting,
        setSeedSubmitting,

        deleteSeedAutoTreat,
        seedDeleting,

        creatingPage,
        setCreatingPage,
        detailPage,
        setDetailPage,

        setActiveSeedAutoTreat,
        activeSeedAutoTreat,

        setActiveMachine,
        activeMachine,

        fetchOptions,
        options,

        handleGenericFilterChange,
        genericFilter,
        handleStatusFilterChange,
        statusFilter,
        handleApiLinkedFilterChange,
        apiLinkedFilter,
      }}
    >
      {children}
    </SeedsAutoTreatContext.Provider>
  );
};

export { SeedsAutoTreatContextProvider, SeedsAutoTreatContextConsumer, SeedsAutoTreatContext };

import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoTreatTransaction } from '../model';

interface TreatmentProductsContextInterface {
  errorMessage?: any;
  exportEmail?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  // emailTransaction?: (transactionId?: string, email?: string, utcOffset?: any, onSuccess?: () => void) => Promise<void>;
  fetchPaginatedTreatmentProducts?: (url: string) => Promise<void>;
  fetchTreatmentProducts?: (combined: boolean, filterParams?: string) => Promise<void>;
  includeProducts?: boolean;
  setIncludeProducts?: any;
  treatmentProductMetrics?: Record<string, string>;
  treatmentProducts?: AutoTreatTransaction[];
  treatmentProductsLoading?: boolean;
}

const TreatmentProductsContext = React.createContext<TreatmentProductsContextInterface>({});

const TreatmentProductsContextConsumer = TreatmentProductsContext.Consumer;
const TreatmentProductsContextProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [exportEmail] = React.useState<string>('');
  const [hasYearlyOverview, setHasYearlyOverview] = React.useState<boolean>(true);
  const [includeProducts, setIncludeProducts] = React.useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [treatmentProductMetrics, setTreatmentProductMetrics] = React.useState<Record<string, string>>({});
  const [treatmentProducts, setTreatmentProducts] = React.useState<AutoTreatTransaction[]>([]);
  const [treatmentProductsLoading, setTreatmentProductsLoading] = React.useState<boolean>(true);

  const fetchTreatmentProducts = async (combined: boolean, filterParams?: string) => {
    setTreatmentProductsLoading(true);
    setErrorMessage('');

    const cachedMachines = JSON.parse(localStorage.getItem('autoTreatMachines') || '[]');
    // for simplicity we are passing the timePeriod and dates through session storage
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    let timeParams = {}

    if (cachedStartDate && cachedEndDate) {
      timeParams = cachedTimePeriod !== 'Custom'
        ? { time_period: cachedTimePeriod || '' }
        : {
            dateandtimeordercomplete_after: cachedStartDate || '',
            dateandtimeordercomplete_before: cachedEndDate || '',
          };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get(`api/autotreat/analytics/transactions/treatment_products${combined ? '/combined' : ''}/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setTreatmentProducts(response.data.results);
        setTreatmentProductsLoading(false);
        setHasYearlyOverview(response.data.yearly_overview);
        setTreatmentProductMetrics({
          total_records: response.data.yearly_overview[0]?.record_count || 0,
          target_amt_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.target_amt_total || 0).toString(),
          actual_amt_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.actual_amt_total || 0).toString(),
          accuracy_with_uom: Math.ceil(response.data.yearly_overview[0]?.accuracy || 0).toString(),
          seed_units_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.seed_units_total || 0).toString(),
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTreatmentProducts([]);
        setTreatmentProductsLoading(false);
      });
  };

  const fetchPaginatedTreatmentProducts = async (url: string) => {
    setTreatmentProductsLoading(true);
    setErrorMessage('');

    axios
      .get(url, { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } })
      .then((response: any) => {
        setTreatmentProducts(response.data.results);
        setTreatmentProductsLoading(false);
        setHasYearlyOverview(response.data.yearly_overview);
        setTreatmentProductMetrics({
          total_records: response.data.yearly_overview[0]?.record_count,
          target_amt_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.target_amt_total).toString(),
          actual_amt_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.actual_amt_total).toString(),
          accuracy_with_uom: Math.ceil(response.data.yearly_overview[0]?.accuracy).toString(),
          seed_units_total_with_uom: Math.ceil(response.data.yearly_overview[0]?.seed_units_total).toString(),
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTreatmentProducts([]);
        setTreatmentProductsLoading(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview?,
    combineMachineTotals?,
  ) => {
    setTreatmentProductsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoTreatMachines') || '[]');
    // for simplicity we are passing the timePeriod and dates through session storage
    const time_period = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    // Parse filterParams into an object
    const filterParamsObj = Object.fromEntries(new URLSearchParams(filterParams));

    // Add date and time fields if timePeriod is 'Custom'
    if (time_period === 'Custom' && cachedStartDate && cachedEndDate) {
      filterParamsObj['dateandtimeordercomplete_after'] = cachedStartDate || '';
      filterParamsObj['dateandtimeordercomplete_before'] = cachedEndDate || '';
    } 

    // Rebuild filterParams from the updated object
    filterParams = new URLSearchParams(filterParamsObj).toString();

    axios
      .post<any, any>(
        `api/autotreat/analytics/exports/treatment_products`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          time_period,
          filterType,
          combineMachineTotals,
          yearlyOverview,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setSnackbarOpen(true);
        setTreatmentProductsLoading(false);
      })
      .catch((errorMessage) => {
        setSnackbarOpen(false);
        setTreatmentProductsLoading(false);
        setErrorMessage(errorMessage.message);
        setSnackbarOpen(true);
      });
  };

  return (
    <TreatmentProductsContext.Provider
      value={{
        errorMessage,
        exportEmail,
        exportTransactions,
        fetchPaginatedTreatmentProducts,
        fetchTreatmentProducts,
        includeProducts,
        setIncludeProducts,
        treatmentProductMetrics,
        treatmentProducts,
        treatmentProductsLoading,
      }}
    >
      {children}
    </TreatmentProductsContext.Provider>
  );
};

export { TreatmentProductsContextProvider, TreatmentProductsContextConsumer, TreatmentProductsContext };

/* eslint-disable react-hooks/exhaustive-deps, react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@mui/material';
import { Importer, ImporterField } from '../../../modules/csvImporter';
import 'react-csv-importer/dist/index.css';
import { SeedsAutoTreatValidationsContext } from '../contexts/SeedsAutoTreatValidationsContext';
import { SeedsAutoTreatImportContext } from '../contexts/SeedsAutoTreatImportContext';
import SeedImportStepper from './SeedsImportStepper';
import { sanitizeInputs } from '../../../modules/shared/utils/sanitizeInputs';

interface SeedsAutoTreatcsvImportProps {
  apiPartner?: string;
  machineSerialNumber: any;
}

const SeedsAutoTreatcsvImport: React.FunctionComponent<SeedsAutoTreatcsvImportProps> = (props) => {
  const { apiPartner, machineSerialNumber } = props;

  const [activeStep, setActiveStep] = React.useState(0);

  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
  const [triggerUnAcceptFile, setTriggerUnAcceptFile] = React.useState<number>(0);

  const { validatecsvImportRow } = React.useContext(SeedsAutoTreatValidationsContext);
  const { setPassedRecords, passedRecords, setFailedRecords, sendSeedsBulkAutoTreat, failedRecords } =
    React.useContext(SeedsAutoTreatImportContext);

  const handleEnableNext = (value) => {
    setNextButtonDisabled(value);
  };

  const handleSubmit = () => {
    sendSeedsBulkAutoTreat(machineSerialNumber, passedRecords, apiPartner);
  };

  const triggerNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUnAcceptFile = () => {
    setTriggerUnAcceptFile((triggerUnAcceptFile) => triggerUnAcceptFile + 1);
  };

  const triggerBackStep = () => {
    setPassedRecords([]);
    setFailedRecords([]);
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if (activeStep === 1) {
      handleUnAcceptFile();
    }
  };

  const handleValidateRows = (rows) => {
    const tempPassdRecords = [];
    const tempFailedRecords = [];
    const compoundIdCounts = {};

    const tempSanitizedRows = sanitizeInputs(rows);

    tempSanitizedRows.forEach((row) => {
      const compoundId =
        (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');
      compoundIdCounts[compoundId] = (compoundIdCounts[compoundId] || 0) + 1;
    });

    tempSanitizedRows.forEach((row, index) => {
      const rowValidation: { error: boolean; errorMessage?: string } = validatecsvImportRow(row);

      const validValues = ['0', '1', 'true', 'false'];

      if (!validValues.includes(row.active.toLowerCase())) {
          rowValidation.error = true;
          rowValidation.errorMessage = '"Active" must be "0", "1", "true", or "false".';
      }

      const isConvertibleToInt = (value) => {
        return typeof value === 'string' && !isNaN(parseInt(value, 10)) && parseInt(value, 10).toString() === value;
      };

      if (!isConvertibleToInt(row.seed_size)) {
        rowValidation.error = true;
        rowValidation.errorMessage = '"Seed size" must be a number.';
      }

      if (!isConvertibleToInt(row.unit_size_wt)) {
        rowValidation.error = true;
        rowValidation.errorMessage = '"unit_size_wt" must be a number.';
      }

      if (!isConvertibleToInt(row.unit_size_cnt)) {
        rowValidation.error = true;
        rowValidation.errorMessage = '"unit_size_cnt" must be a number.';
      }

      if (apiPartner) {
        const compoundId =
          (row.partner_record_id_1 || '') + (row.partner_record_id_2 || '') + (row.partner_record_id_3 || '');

        if (row.partner_record_id_1 === null || row.partner_record_id_1 === '') {
          rowValidation.error = true;
          rowValidation.errorMessage = 'partner_record_id_1 cannot be blank';
        } else if (compoundIdCounts[compoundId] > 1) {
          rowValidation.error = true;
          rowValidation.errorMessage = 'Duplicate partner id found';
        }
      }

      if (rowValidation.error) {
        tempFailedRecords.push({ ...row, errorMessage: rowValidation.errorMessage });
      } else {
        tempPassdRecords.push(row);
      }
    });
    setPassedRecords((prevPassedRecords) => [...prevPassedRecords, ...tempPassdRecords]);
    setFailedRecords((prevFailedRecords) => [...prevFailedRecords, ...tempFailedRecords]);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={1}>
        <Grid item marginTop={1} xs={12}>
          <Importer
            activeStep={activeStep}
            enableNext={handleEnableNext}
            dataHandler={async (rows, { startIndex }) => {
              handleValidateRows(rows);
            }}
            passedRecords={passedRecords}
            failedRecords={failedRecords}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            stepperComponent={
              <SeedImportStepper
                triggerNextStepProp={triggerNextStep}
                triggerBackStepProp={triggerBackStep}
                activeStep={activeStep}
                nextDisabled={nextButtonDisabled}
              />
            }
            unAcceptFile={triggerUnAcceptFile}
            sendToMachine={handleSubmit}
            apiPartner={apiPartner}
            columnsTitle="Autotreat Seed Fields"
          >
            <ImporterField name="variety" label="variety" />
            <ImporterField name="seed_lot_data" label="seed_lot_data" optional />
            {/* <ImporterField name="inventory" label="inventory" optional /> */}
            <ImporterField name="shipment_number" label="shipment_number" optional />
            <ImporterField name="seed_variety_desc" label="seed_variety_desc" optional />
            <ImporterField name="seed_size" label="seed_size" optional description="Default determined by crop" />
            <ImporterField name="crop_name" label="crop_name" optional />
            <ImporterField name="company" label="company" optional />
            <ImporterField name="brand" label="brand" optional />
            <ImporterField name="unit_size_wt" label="unit_size_wt" description="Default: 50" optional />
            <ImporterField name="unit_size_cnt" label="unit_size_cnt" optional />
            <ImporterField name="seed_origin" label="seed_origin" optional />
            <ImporterField name="seed_germ" label="seed_germ" optional />
            <ImporterField name="seed_test_date" label="seed_test_date" optional />
            <ImporterField name="seed_notes" label="seed_notes" optional />
            <ImporterField name="active" label="active" description="Default: True" optional />
            {apiPartner && (
              <>
                {/* <ImporterField name="api_partner" label="api_partner" description='testing1'  optional /> */}
                <ImporterField name="partner_record_id_1" label="partner_record_id_1" />
                <ImporterField
                  name="partner_record_id_2"
                  label="partner_record_id_2"
                  description="testing17"
                  optional
                />
                <ImporterField
                  name="partner_record_id_3"
                  label="partner_record_id_3"
                  description="testing18"
                  optional
                />
              </>
            )}
          </Importer>
        </Grid>
      </Grid>
    </>
  );
};

export default SeedsAutoTreatcsvImport;

import React from 'react';
import { format, parse } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

const productDetailColumns = (uom = 'gallons'): IDataTableColumn<any>[] => {
  const columns = [
    {
      name: `Date (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
      selector: 'work_order_complete_date',
      sortable: true,
      cell: (row: any) => {
        const rawDate = row?.work_order_complete_date?.split('.')[0]; // Clean the date
    
        return (
          <p>
            {rawDate ? format(new Date(`${rawDate}Z`), 'MM/dd/yyyy hh:mm aaa') : ''}
          </p>
        );
      }
    },
    {
      name: 'Machine',
      selector: 'machine_name_with_serial',
      sortable: true,
    },
    {
      name: 'Order Name',
      selector: 'work_order_name',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: 'customer_name',
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: 'product_name',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'product_type',
      sortable: true,
    },
    {
      name: 'Density',
      selector: 'product_density_with_uom',
      sortable: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_density_with_uom) || 0;
        const valB = parseFloat(b.product_density_with_uom) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Target Amount',
      selector: 'product_delivered_target_amt_with_uom',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_target_amt_with_uom) || 0;
        const valB = parseFloat(b.product_delivered_target_amt_with_uom) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Actual Amount',
      selector: 'product_delivered_actual_amt_with_uom',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_actual_amt_with_uom) || 0;
        const valB = parseFloat(b.product_delivered_actual_amt_with_uom) || 0;
        return valA - valB;
      },
    },
    {
      name: 'Accuracy',
      selector: 'product_delivered_accuracy',
      sortable: true,
      right: true,
      sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
        const valA = parseFloat(a.product_delivered_accuracy) || 0;
        const valB = parseFloat(b.product_delivered_accuracy) || 0;
        return valA - valB;
      },
    },
  ];

  return columns;
};

export default productDetailColumns;
